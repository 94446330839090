import { LineupSectionItemProps } from "@/components/domains/lp/LineupSection/LineupSectionItem";

import ImgThumbBYShioYakisobaOmote from "$/images/pkg/thum_by_shioyakisoba.png";
import ImgThumbBYSauceYakisobaOmote from "$/images/pkg/thum_by_yakisoba.png";
import ImgThumbBBChocolateOmote from "$/images/pkg/thumb_bb_chocolate_omote.png";
import ImgThumbBBCurry from "$/images/pkg/thumb_bb_curry_omote.png";
import ImgThumbBBKoshianOmote from "$/images/pkg/thumb_bb_koshian_omote.png";
import ImgThumbBBMapleOmote from "$/images/pkg/thumb_bb_maple_omote.png";
import ImgThumbBBMilkOmote from "$/images/pkg/thumb_bb_milk_omote.png";
import ImgThumbBBSliceOrangeOmote from "$/images/pkg/thumb_bb_slice_orange_omote.png";
import ImgThumbBBSliceRaisinOmote from "$/images/pkg/thumb_bb_slice_raisin_omote.png";
import ImgThumbBCCheeseOmote from "$/images/pkg/thumb_bc_cheese_omote.png";
import ImgThumbBCCocoaOmote from "$/images/pkg/thumb_bc_cocoa_omote.png";
import ImgThumbBCCoconutOmote from "$/images/pkg/thumb_bc_coconut_omote.png";
import ImgThumbBCEarlgreyOmote from "$/images/pkg/thumb_bc_earlgrey_omote.png";
import ImgThumbBCPepperOmote from "$/images/pkg/thumb_bc_pepper_omote.png";
import ImgThumbBPMOmote from "$/images/pkg/thumb_bpm_omote.png";

export const items: LineupSectionItemProps[] = [
  {
    category: "BREAD",
    title: "チョコレート",
    imageSrc: ImgThumbBBChocolateOmote,
    description: "しっとりチョコが折り込まれた、やわもち食感のマーブルチョコパン。",
  },
  {
    category: "BREAD",
    title: "メープル",
    imageSrc: ImgThumbBBMapleOmote,
    description: "まろやかな甘みにほっとする、スティックタイプのメープルパン。",
  },
  {
    category: "BREAD",
    title: "こしあん",
    imageSrc: ImgThumbBBKoshianOmote,
    description: "北海道産小豆の上品な甘み広がるしっとり生地のあんぱん。",
  },
  {
    category: "BREAD",
    title: "ミルク",
    imageSrc: ImgThumbBBMilkOmote,
    description: "北海道産牛乳を使用した、ほんのり甘いミルク風味のもっちりスティックパン。",
  },
  {
    category: "BREAD",
    title: "ミニ食パン・レーズン",
    imageSrc: ImgThumbBBSliceRaisinOmote,
    description: "みずみずしいレーズンの甘みがじゅわっと広がる、サクもち食感のミニ食パン。",
  },
  {
    category: "BREAD",
    title: "ミニ食パン・オレンジ",
    imageSrc: ImgThumbBBSliceOrangeOmote,
    description: "オレンジの爽やかな香りと、ほろ苦くやさしい甘み広がるミニ食パン。",
  },
  {
    category: "BREAD",
    title: "カレー",
    imageSrc: ImgThumbBBCurry,
    description: "揚げずに焼いて仕上げた、やわもち食感の濃厚カレーパン。",
  },
  {
    category: "Pancake Mix",
    title: "パンケーキミックス",
    imageSrc: ImgThumbBPMOmote,
    description: "ふんわりしっとり食感のパンケーキミックス。",
  },
];

export const itemsMore: LineupSectionItemProps[] = [
  {
    category: "Cookies",
    title: "ココア",
    imageSrc: ImgThumbBCCocoaOmote,
    description: "ココアがふんわり香る、落ち着く大人のクッキー。",
  },

  {
    category: "Cookies",
    title: "アールグレイ",
    imageSrc: ImgThumbBCEarlgreyOmote,
    description: "アールグレイの香り引き立つ、気分ほぐれるクッキー。",
  },
  {
    category: "Cookies",
    title: "ココナッツ",
    imageSrc: ImgThumbBCCoconutOmote,
    description: "粗挽きココナッツの風味と香りがひとくちごとに広がる、ほんのり甘いクッキー。",
  },
  {
    category: "Cookies",
    title: "ペッパー",
    imageSrc: ImgThumbBCPepperOmote,
    description: "お酒のお供に！ザクザク食感が特徴のピリッと香るブラックペッパー。",
  },
  {
    category: "Cookies",
    title: "チーズ",
    imageSrc: ImgThumbBCCheeseOmote,
    description: "おやつにサクッと！ザクザク食感が特徴のやさしく香ばしいチェダーチーズ。",
  },
  {
    category: "YAKISOBA",
    registeredMark: false,
    title: "ソース焼きそば",
    imageSrc: ImgThumbBYSauceYakisobaOmote,
    description:
      "懐かしく香ばしいソースがつるつるもちもち麺とよく絡む、全粒粉ベースのソース焼きそば。",
  },
  {
    category: "YAKISOBA",
    registeredMark: false,
    title: "塩焼きそば",
    imageSrc: ImgThumbBYShioYakisobaOmote,
    description:
      "あっさりスパイシーな塩味がつるつるもちもち麺に絡み合う、汁なしタイプの塩焼きそば。",
  },
];
